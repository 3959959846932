.spinner-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #444f5e;
  border-left-color: #60bfda;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.loader-label-loading {
  color: #444f5e;
  text-align: center;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 60px 0 0 -46px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
