@import '../../../styles/global.scss';
@import '../../../styles/darktheme/utilities/variables';
$nav-content-width: 330.92px;
$nav-content-height: 44px;

.navigation-bar-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  height: 100vh;
  left: 0;
  top: 0;
  background: #003747;
  float: left;
}

.navigation-bar-items {
  min-width: 416px;
  height: 100vh;
  gap: 16px;
  z-index: 1;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navigation-bar-items-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 36px 16px 16px 16px;
}

.navigation-bar-top-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  min-width: 68px;
  height: 100%;
}

.navigation-bar-navigation-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 12px 0px 12px;
  justify-self: flex-start;
}

.navigation-bar-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.navigation-bar-logo-image-container {
  margin-bottom: 33px;
}

.navigation-bar-logo-image {
  width: 40px;
  height: 50.256px;
}

.navigation-bar-slide-out-section-container {
  justify-self: flex-end;
  padding-bottom: 24px;
}

.navigation-bar-navigation-image-container {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    background-color: #005f7c;
  }
}

.navigation-content-headline {
  @include heading6;

  color: #191f23;
  margin-bottom: 16px;
}

.navigation-content-search-and-filter {
  display: flex;
  gap: 9px;
  margin-bottom: 16px;
}

.navigation-content-search {
  flex: 1;
  position: relative;
  display: inline-block;
}

.search-icon {
  position: absolute;
  left: 10px;
  /* Adjust the space on the left of the icon */
  top: 50%;
  transform: translateY(-50%);
}

.input-with-icon {
  @include paragraph2;

  width: 100%;
  height: $nav-content-height;
  padding: 12px 14px 12px 40px;
  border-radius: 8px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #b8c5cc;
}

.navigation-content-search-input {
  width: $nav-content-width;
  height: $nav-content-height;
  padding: 12px 14px 12px 14px;
  border-radius: 8px;
  border: 1px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #b8c5cc;
}

.navigation-content-search-btn {
  width: 44px;
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  border: 1px;
  gap: 8px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #b8c5cc;
}

.filter-icon {
  width: 20.000000103277475px;
  height: 20.000000103277475px;
  padding: 4.166666507720947px 2.5px 5.833333492279053px 2.5px;
}

.navigation-content-drawer-container {
  display: flex;
  width: 416px;
  height: 100vh;
  padding: 36px 16px 16px 16px;
  gap: 16px;
}

.navigation-content-frame {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 384px;
  gap: 4px;
  overflow-y: auto;
  overflow-x: auto;
}

.navigation-content-frame-item {
  width: 100%;
  min-width: 250px;
  height: 40px;
  padding: 0px 12px;
  border-radius: 6px;
  gap: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.navigation-content-frame-item:hover,
.navigation-content-frame-asset:hover {
  background: #458ca2;
}

.navigation-content-frame-item-content {
  width: 100%;
  display: flex;
}

.navigation-content-frame-item-carrot {
  width: 20px;
  height: 20px;
}

.navigation-content-well-enable-status {
  display: flex;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 4px;
}

.navigation-content-frame-item-text {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  color: #323f46;
}

.navigation-content-frame-item-count {
  text-align: center;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 4px;
  max-height: 24px;
}

.navigation-content-frame-asset {
  display: flex;
  width: 100%;
  min-width: 250px;
  min-height: 40px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  position: relative;
}

.item-selected {
  background: #dcf1f7;
}

.navigation-content-frame-asset:hover {
  background: #458ca2;
}

.item-selected:hover {
  background: #458ca2;
}

.chevron {
  width: 20px;
  height: 20px;
}

.placeholder-image {
  position: absolute;
  top: 50%;
  left: 20px;
  /* Adjust the position of the image based on your preference */
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url('../../../images/search-sm.svg');
  /* Replace with the path to your image */
  background-size: cover;
}

.navigation-content-search-input::placeholder {
  padding: 35px;
}

.notification-content-top-container {
  display: flex;
  flex-direction: row;
  padding: 0px 0px 16px 0px;
  justify-content: space-between;
}

.notification-content-button-filter {
  padding-top: 5px;
}

.notification-content-filter {
  margin-left: auto;
  margin-right: 0;
  position: relative;
}

.navbar-container {
  width: 0;
}

.navigation-content-variable-speed-pill {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 9px;
  height: 100%;
  border-radius: 4px;
}
