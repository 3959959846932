.property-list-item {
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e7ecee;
  padding: 0 24px;
  gap: 12px;
}

.property-list-item .description,
.property-list-item .value {
  white-space: nowrap;
}

.property-list-item .description {
  width: 100%;
  text-overflow: ellipsis;
  font-size: 16px;
}

.property-list-item .value {
  text-align: right;
}

.property-list-item .error {
  color: #fda29b;
  border: 1px solid #fda29b;
  border-radius: 4px;
  font-size: 14px;
  padding: 2px 10px 2px 10px;
}

.property-value-redsquare {
  display: flex;
  color: white;
  background-color: red;
  font-size: 10px;
  float: right;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.property-value-title-width {
  width: 100%;
}
