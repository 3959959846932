.trend-custom-views-dropdown {
  display: flex;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #191f23;
  width: 100%;
}

.trend-custom-views-dropdown .trend-custom-views-dropdown-btn {
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-100, #d0d8dd);
  background: var(--shades-white, #fff);
  justify-content: space-between;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  overflow: hidden;
}

.trend-custom-views-dropdown-content {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);
  position: absolute;
  width: 320px;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  max-height: 500px;
  overflow-y: auto;
  overflow-wrap: break-word;
  font-weight: normal;
}

.theme-dark {
  .trend-custom-views-dropdown-content {
    background: var(--darkmode-800, #001023);
    color: #f7f9f9;
    border-color: #4a5463;

    .item-selected {
      display: flex;
      background-color: transparent;
      justify-content: space-between;
    }

    .check-icon {
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
    }
  }

  .tracking-wide li {
    padding: 10px 20px;
    background: #131f2e;
    margin-bottom: 12px;
    color: white !important;
    border-radius: 6px;
  }

  .card-calendar {
    .bg-white:not(input) {
      background: #001023;
    }
  }
}

.trend-custom-views-dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}

.trend-custom-views-dropdown-content .item:hover {
  background: #458ca2;
}

.trend-custom-views-dropdown-content .item-selected {
  padding: 10px;
  cursor: pointer;
  background: #dcf1f7;
}

.globe-icon {
  display: inline-block;

  img {
    width: 14px;
    margin: 0 0 0 5px;
  }
}

.check-icon-wrap {
  flex-grow: 1;
  justify-content: end;
  display: flex;
}
