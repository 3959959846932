.card-date-list-container {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  border: 1px solid #d0d8dd;
  height: 480px;
  min-width: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0.25rem;
}
