.trend-label-group-chart {
  display: flex;
  width: 100%;
  height: 100%;
}

.trend-group-chart {
  width: 100%;
  height: 100%;
}

.trend-label-item-data {
  display: flex;
  align-items: center;
}

.trend-group-label-container {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-align {
  margin-right: auto;
  padding-right: 15px;
}
