.widget-data-item-data {
  padding: 5px;
  display: flex;
  align-items: center;
  align-self: stretch;
}

.widget-data-item-title {
  color: var(--Neutral-Grey-800, var(--Neutral-Grey800, #191f23));
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  line-height: 14px;
  flex: 1 0 0;
  margin-left: 5px;
}

.widget-data-item-value {
  color: var(--Neutral-Grey-800, var(--Neutral-Grey800, #191f23));
  text-align: right;
  font-size: 12px;
  font-weight: var(--font-weight-medium);
  line-height: 14px;
  margin-left: 7px;
}

.widget-data-item-bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
