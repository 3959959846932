.analysis-result-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  border: 1px solid #d0d8dd;
  background: var(--shades-white, #fff);
}

.analysis-result-item-header-container {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e7ecee;
  background-color: transparent;
}

.analysis-result-item-header-text {
  color: #191f23;
  font-size: 0.875rem;
  font-weight: var(--font-weight-bolder);
  line-height: 1.25rem;
}

.analysis-chart-plus-legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
