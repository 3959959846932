.gl-valve-analysis-table-container {
  overflow: auto;
  border-radius: 4px;
  border: 1px solid var(--neutral-grey-100, #d0d8dd);
  margin: 2px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  max-height: 500px;
}

.gl-valve-analysis-table-header-cell {
  height: 44px;
  padding: 12px 8px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px 0px 0px 0px;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  background: var(--neutral-grey-25, #f7f9f9);
}

.gl-valve-analysis-table-header-item {
  align-items: center;
  gap: 8px;
  padding-right: 20px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
}

.gl-valve-analysis-table-row-name {
  height: 44px;
  padding: 12px 8px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  font-size: 12px;
  min-width: 100px;
}

.gl-valve-analysis-table-row-link {
  color: #00789e;
  transform: rotate(-0.11deg);
  text-decoration: none;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.gl-valve-analysis-table-row-link:hover {
  color: #005f7c;
  transform: rotate(-0.11deg);
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.gl-valve-analysis-table-row-general {
  height: 44px;
  padding: 12px 8px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  font-family: Mulish;
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5e68;
  min-width: 70px;
  max-width: 100px;
}

.gl-valve-analysis-table-row-alarms {
  height: 44px;
  padding: 12px 8px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  padding: 12px 8px;
}

.gl-valve-analysis-valve-analysis-table {
  border-collapse: collapse !important;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  width: 100%;
}

.gl-valve-analysis-table-row-name > td {
  padding-left: 10px;
}

.gl-valve-analysis-valve-table-top-align {
  top: -16px;
}

.gl-valve-analysis-valve-analysis-table-overflow {
  overflow: scroll;
}
