.trend-group-list {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Neutral-Grey25, #f7f9f9);
  width: 270px;
}

.trend-group-no-list {
  display: flex;
}

.trend-group-view-container {
  overflow-y: auto;
  height: 764px;
  margin-left: -15px;
}

.trend-group-view-chart-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
}
