.trend-group-container {
  display: flex;
  height: 168px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 4px;
  border: var(--Number, 1px) solid var(--Neutral-Grey100, #d0d8dd);
  background: var(--Shades-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
}

.trend-group-settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.trend-group-header-container {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--Neutral-Grey100, #d0d8dd);
}

.trend-group-header-text {
  color: var(--Shades-Black, #000);
  font-size: 14px;
  font-weight: var(--font-weight-medium);
  line-height: normal;
}

.trend-group-label-icon {
  width: 24px;
  height: 24px;
}

.trend-group-label-body-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  overflow-x: hidden;
  padding: 0 0.5rem;
}
.trend-loader {
  height: 100px;
}
