//mixins
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin font-size($mobile, $tablet, $desktop) {
  font-size: #{desktop};
  /*  @include mq($until: desktop) {
    font-size: #{tablet};
  }
  @include mq($until: tablet) {
    font-size: #{mobile};
  } */
  @media (max-width: 992px) {
    font-size: #{tablet};
  }
  @media (max-width: 767px) {
    font-size: #{mobile};
  }
}

// line-height
@mixin line-height($mobile, $tablet, $desktop) {
  line-height: #{desktop};
  @media (max-width: 992px) {
    line-height: #{tablet};
  }
  @media (max-width: 767px) {
    line-height: #{mobile};
  }
}

// letter-spacing
@mixin letter-spacing($mobile, $tablet, $desktop) {
  letter-spacing: #{desktop};
  @media (max-width: 992px) {
    letter-spacing: #{tablet};
  }
  @media (max-width: 767px) {
    letter-spacing: #{mobile};
  }
}

//css3 transition
@mixin transition($transition) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

//css3 transform
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// Transform Origin
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

// border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// box shadow
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  -ms-box-sizing: $box-model;
  box-sizing: $box-model;
}

// object-fit
@mixin object-fit($objectfit) {
  -webkit-object-fit: $objectfit;
  -moz-object-fit: $objectfit;
  -ms-object-fit: $objectfit;
  object-fit: $objectfit;
}

// 16:9 aspect-ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

// Display Flexbox
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Inline flex display
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// Flex Line Wrapping
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($val: nowrap) {
  -webkit-flex-wrap: $val;
  -moz-flex-wrap: $val;
  @if $val == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $val;
  }
  flex-wrap: $val;
}

// Flex grow factor
@mixin flex-grow($val: 1) {
  -webkit-box-flex: $val;
  -moz-box-flex: $val;
  -webkit-flex-grow: $val;
  -ms-flex: $val;
  flex-grow: $val;
}

// Cross-axis Alignment
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($val: stretch) {
  @if $val == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $val == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $val;
    -moz-box-align: $val;
    -ms-flex-align: $val;
  }
  -webkit-align-items: $val;
  align-items: $val;
}

// Axis Alignment
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($val: flex-start) {
  @if $val == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $val == flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  } @else if $val == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $val == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $val;
    -moz-box-pack: $val;
    -ms-flex-pack: $val;
  }
  -webkit-justify-content: $val;
  justify-content: $val;
}

// Flex Flow Direction
// row (default) | row-reverse | column | column-reverse
@mixin flex-direction($direction: row) {
  @if $direction == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $direction == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $direction == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}


// input type text styling
@mixin input-styling {
  border: 1px solid $primary-light-border;
  border-radius: 6px;
  height: 35px;
  padding: $padding-xs $padding-md;
  max-width: 100%;
  width: $full-unit;
}
