.filter-icon-container {
  display: flex;
  justify-content: flex-end;
}

.filter-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 10px;
  border-radius: 0.375rem;
}

.filter-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
  gap: 10px;
}

.filter-item:last-child {
  border-bottom: none;
}

.filter-dropdown-actions {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
