.servererror-background {
  background-color: white;
  width: 40%;
  height: 50%;
  top: 20%;
  left: 30%;
  position: absolute;
  border: solid 1px rgb(244, 236, 236);
  border-radius: 10px;
  padding: 20px;
}

.servererror-message {
  display: inline-block;
  width: 40%;
}

.servererror-logo {
  display: inline-block;
  height: 87%;
  position: absolute;
  width: 55%;
  box-shadow: 5px;
  box-shadow: 1px 2px 2px 2px rgb(225, 228, 230);
  border-radius: 10px;
}

.servererror-inlineblock {
  display: inline-block;
}

.servererror-xspocmargin {
  margin-top: 165px;
}

.servererror-imgmargintop {
  margin-top: 115px;
}
