.onboarding-shell {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
}

.onboarding-shell-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
}

.onboarding-shell-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, #353f4f, #131f2e);
}

.onboarding-shell-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  max-width: 500px;

  .onboarding-shell-header-logo-container img {
    margin-left: 48px;
  }

  .default-view-container {
    max-height: 250px;
    overflow: hidden;
    overflow-y: scroll;

    .prompt-checkbox-label {
      color: #fff !important;
    }

    .expanded-asset,
    .checkbox-container {
      border-color: #3c4655;
    }

    .expanded-asset {
      padding-left: 25px;
    }

    .custom-checkbox {
      border-color: #3c4655;
      background: transparent;
    }
  }
}

.onboarding-shell-left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #001023;
  flex: 1;
  width: 100%;

  .login-title,
  .login-sub-title,
  .input-label,
  .input-field,
  .setup-heading,
  .setup-message,
  .onboarding-shell-back,
  .onboarding-shell-skip,
  .onboarding-steps-description {
    color: #f7f9f9 !important;
  }

  .arrowleft {
    filter: brightness(5);
    opacity: 0.7;
  }

  .onboarding-shell-footer {
    color: #b8c5cc !important;
  }

  .default-view-container {
    background-color: #131f2e;
    border-color: #3c4655;

    * {
      color: #3c4655 !important;
    }
  }

  .setup-heading {
    margin: 0;
  }
}

.onboarding-shell-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 4rem;
}

.onboarding-shell-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 96px;
  width: 100%;
  padding: 32px;
}

.onboarding-shell-navigation-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 32px;
}

.onboarding-shell-navigation-buttons button {
  transition: opacity 0.8s;
  opacity: 1;
  visibility: visible;
}

.onboarding-shell-navigation-buttons button.hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.onboarding-shell-back {
  display: flex;
  padding: 12px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #a0b1bb;
  font-size: 16px;
  font-weight: var(--font-weight-bolder);
  height: 44px;

  &.hide {
    visibility: hidden;
  }
}

.onboarding-shell-skip {
  display: flex;
  font-size: 16px;
  font-weight: var(--font-weight-bolder);
  text-decoration-line: underline;
}

.onboarding-shell-continue {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border-color: #fff;
  background: #005f7c;
  color: #fff;
  font-size: 16px;
  font-weight: var(--font-weight-bolder);
  height: 44px;
}

.onboarding-shell-steps {
  width: 100%;
}

.onboarding-steps-header {
  font-size: 39px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: -1.56px;
}

.onboarding-steps-description {
  padding-top: 1.5rem;
  color: var(--neutral-grey-600, #4b5e68);
  font-size: 16px;
  line-height: 18px;
}

.onboarding-shell-right-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
}

.onboarding-shell-image {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 30px;
}

.onboarding-shell-right-header {
  color: var(--shades-white, #fff);
  text-align: center;
  font-size: 23px;
  font-weight: var(--font-weight-bolder);
  line-height: 28px;
  letter-spacing: -0.46px;
  padding-bottom: 0.5rem;
}

.onboarding-shell-right-text {
  color: var(--primary-indigo-25, var(--primary-indigo-25, #f4fbfc));
  text-align: center;
  font-size: 16px;
  font-weight: var(--font-weight-bolder);
  line-height: 19px;
}

.image-container {
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex: 1 0 0;
  align-self: stretch;
}

::-webkit-scrollbar-thumb {
  background-color: #4a5463;
  /* color of the scrollbar thumb */
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #1c2838;
}

/* Optional: Handle on hover state */
::-webkit-scrollbar-thumb:hover {
  background-color: #5d6675;
}

#onboarding-image {
  height: 'fit-content !important';
  width: 'fit-content !important';
}