/* For WebKit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #808080;
  /* color of the scrollbar thumb */
  border-radius: 6px;
  /* roundness of the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* color of the scrollbar track */
}

.theme-dark {
  ::-webkit-scrollbar-corner {
    background-color: #1c2838;
    /* color of the scrollbar corner */
  }
}

/* Optional: Handle on hover state */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.dashboard-first-block {
  position: relative;
}

.overlay-section {
  position: absolute;
  right: 1rem;
  top: auto;
  margin-top: -25px;
  z-index: 1;

  &.first {
    right: 15rem;
  }

  &.sec {
    right: 2rem;
  }

  .card {
    background: #fff !important;
  }
}

.date-input {
  margin: 0 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  border-color: #d0d8dd;
}

.react-grid-layout {
  .react-grid-item {
    z-index: 9;

    .expand-btn {
      z-index: 999999;
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay--after-open {
    z-index: 9;

    .ReactModal__Content {
      padding: 0 !important;
      border: 1px solid #5d6675 !important;
      background: #131f2e !important;
      width: 55rem;
    }

    .modal-header {
      border-bottom: 1px solid #d0d8dd;
      padding: 1.5rem 1rem;
      margin-bottom: 0.5rem;

      h3 {
        color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }

    .modal-content {
      padding: 1.5rem 1rem;

      .chart-wrap {
        background: #131f2e;
        text-align: center;
        padding: 1rem;
      }

      p {
        color: #fff;
      }
    }
  }
}

.dashboard-count.ui {
  &>div {
    border-right: 1px solid #5d6675;
    padding: 0 4rem 0 1.5rem;

    &:last-child {
      padding: 0 0.5rem 0 0;
      border-right-width: 0;
    }

    .link {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;

      &:hover {
        background-color: #000;
      }
    }
  }
}

.table-container.w-full {
  margin: 0;
  border: 0;

  .document-table {
    width: 100%;

    th,
    td {
      text-align: left;
      padding: 1rem;
      border-bottom: 1px solid #d0d8dd;
    }
  }
}

.right-modal {
  background: #f1f4f5;
}

.custom-text-input {
  padding: 0.75rem 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
}

.relative.block {
  .custom-text-input {
    padding-left: 2rem;
  }
}

.outer-container {
  .card-viewer-data>P {
    color: #000;
  }

  .group-status-container {
    margin: 0rem 1.5rem;
    border-radius: 0.25rem;
  }

  .table-header-item {
    span.sort-image {
      display: inline-block;
      padding-left: 0.25rem;
    }
  }

  .table-row-alarms {
    .badge {
      mix-blend-mode: multiply;
    }
  }

  .options.end {
    height: 2.5rem;
    margin-top: 1rem;

    .tableviewoption-icon,
    .vertical-dividerflex flex-row flex-grow flex-shrink {
      margin: 0.5rem 0 0;
    }
  }

  .content-headrow {
    padding: 1rem;
  }
}

.custom-layout {
  padding: 1.25rem 1rem;
}

.page-layout {
  padding: 1rem 1rem;

  &.analysis {
    div.col>div {
      margin: 0.5rem;
    }
  }
}

.select-wrap {
  .chart-select select {
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
  }
}