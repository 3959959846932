@import '../utilities/variables.scss';

.theme-dark {
  .toggle-dropdown-container {
    .shadow-lg.ring-black {
      width: 320px;
      border: 1px solid #4a5463;
      border-radius: 0.5rem;

      > div:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
      }

      > div:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
      }
    }
  }

  .Schduled-speed {
    .heading {
      color: #fff;
    }
  }

  .gl-valve-analysis-valve-analysis-table {
    td {
      color: #f7f9f9;
    }

    .gl-valve-analysis-table-row-name {
      border-bottom-color: #444f5e;
    }
  }

  .card-viewer-full-screen-override {
    background-color: #001023;
  }

  .card-viewer-date-picker-container .card-calendar {
    border-width: 0;
  }

  .border-gray-300 {
    border-color: #4a5463;
  }

  button.hover\:bg-gray-100:hover {
    background-color: #4a5463;
  }

  .asset-nav-bar {
    background: #131f2e;
    border: 1px solid #4a5463;
  }

  .asset-ui-buttons-container {
    background-color: $dark-secondary;
  }

  .trend-group-container {
    background-color: #111e30;
    border: 1px solid #4a5463;
  }

  .trend-group-list {
    background-color: #111e30;
    border: 1px solid #4a5463;
  }

  .trend-group-header-container {
    border-bottom: 1px solid #4a5463;
  }

  .right-modal {
    background: #131f2e;
  }

  .table-container.w-full {
    color: #fff;

    .table-header-cell {
      background: transparent;
    }

    .document-table {
      width: 100%;

      th,
      td {
        color: #fff;
        border-bottom: 1px solid #4a5463;
      }
    }
  }

  .asset-status-table-border {
    border-color: #4a5463;
  }

  .asset-status-container-title-text {
    color: #f7f9f9;
  }

  .asset-status-key-value-text {
    span {
      color: #f7f9f9;
    }
  }

  .table-diagram-single-value-container {
    background-color: #131f2e;
    border-color: #4a5463;
    color: #f7f9f9;
  }

  .table-diagram-multi-value-container {
    background-color: #131f2e;
    border-color: #4a5463;

    .bottom-divider {
      border-color: #4a5463;
    }
  }

  .facility-table-bottom-border {
    border-color: #4a5463;
  }

  .facility-inner-table-outer-container {
    background-color: #001023;
  }

  .facility-inner-table-container {
    border-color: #4a5463;
    background: linear-gradient(181.1deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
  }

  .facility-basis-sevenths {
    color: #f7f9f9;
  }

  .facility-text-fourths {
    color: #f7f9f9;
  }

  .asset-status-basis-seconds {
    color: #f7f9f9;
  }

  .asset-status-basis-thirds {
    color: #f7f9f9;
  }

  .facility-display-state-good {
    border-color: #32d583;
    color: #32d583 !important;
  }

  .facility-display-state-warning {
    border-color: #fdb022;
    color: #fdb022 !important;
  }

  .facility-display-state-error {
    border-color: #f97066;
    color: #f97066 !important;
  }

  .facility-display-state-normal {
    border-color: #4a5463;
    color: #4a5463 !important;
  }

  .asset-status-no-rows {
    color: #f7f9f9;
  }

  /* For WebKit Browsers (Chrome, Safari) */
  .overlay-section {
    .card {
      background: #001023 !important;
    }
  }

  .date-input {
    border-color: #4a5463;
  }

  .card.asset-location {
    .table-cell {
      border: 1px solid #4a5463;
    }

    .table-body-section {
      td {
        color: $white;
      }
    }
  }

  .set-point-wrap {
    .userblock > .card {
      background-color: #0f1d2f;
      border-color: #4a5463;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4a5463;
    /* color of the scrollbar thumb */
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #1c2838;
  }

  /* Optional: Handle on hover state */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #5d6675;
  }

  .selected-option {
    color: $white;
    border-color: #4a5463;
  }

  .dropdown-list {
    background-color: #1c2838;
    border-color: #4a5463;

    li {
      color: #b8c5cc;

      &:hover {
        background-color: #5d6675;
      }
    }
  }

  .setpoint-btn {
    border-color: $dark-light-shade3;
    background: $dark-secondary;
    color: $dark-light-shade3;
  }

  .cust-drop {
    & > div {
      min-width: 10rem;
      padding: 2px;
      background-color: #131f2e;
      border: 1px solid #5d6675;
      border-radius: 5px;

      & > div:last-child {
        background-color: #131f2e;
      }

      &:hover {
        border-color: #131f2e;
      }
    }

    & > div > div > div > div {
      color: #fff;
    }
  }

  background-color: $dark-primary;

  & .navigation-bar-container {
    background: $dark-tertiary;

    & .navigation-bar-navigation-image-container {
      border: solid $dark-tertiary;

      &:hover {
        background-color: $dark-light-shade1;
      }
    }
  }

  .navigation-content-variable-speed-pill {
    background-color: #5dabbb;
  }

  .navigation-content-well-enable-status.enabled {
    background-color: #027a48;
  }

  .navigation-content-well-enable-status.disabled {
    background-color: #f97066;
  }

  & .navigation-bar-items {
    background-color: $dark-primary;

    & .navigation-content-frame-item-text,
    & .navigation-content-headline {
      color: $dark-text-primary;
    }

    & .navigation-content-search-btn {
      background: $dark-secondary;
      border: 1px solid $dark-light-shade2;
    }

    & .navigation-content-frame-asset {
      background: $dark-primary;
    }

    & .navigation-content-frame-item:hover,
    .navigation-content-frame-asset:hover {
      background-color: var(--darkmode-700, #131f2e) !important;
    }

    & .navigation-content-frame-item-count {
      color: $dark-text-primary;
      background-color: var(--dark-mode-600, #253040);
    }

    & .navigation-content-frame-item-count.active {
      color: var(--Primary-Indigo-700, #005f7c);
      background-color: var(--Primary-Indigo-400, #60bfda);
    }

    .navigation-content-frame-item-text.text-active {
      color: var(--Primary-Indigo-400, #60bfda);
    }

    & .item-selected {
      background: $dark-tertiary;
    }
  }

  & .item-selected {
    background: $dark-tertiary;
  }

  & .group-status-container {
    background-color: $dark-primary;
    border: 1px solid $dark-tertiary;
    @include border-radius(0);

    & .profile-status-header {
      background-color: $dark-secondary;
    }
  }

  & .text {
    color: $dark-text-primary;
  }

  & .divider {
    background: $dark-light-shade3;
  }

  & .ui-button-toolbar {
    border-bottom: 1px solid $dark-light-shade2;
  }

  & .ui-button-toolbar-button {
    color: $dark-text-primary;

    &.ui-button-toolbar-button-active {
      color: $light-primary-shade5;
    }

    &:hover {
      cursor: pointer;
      color: $light-primary-shade5;
    }
  }

  & .card {
    color: $neutral-light-shade3;
    border: 1px solid $dark-light-shade2;
    // background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
    /* Shadows/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background-image: linear-gradient(#1e2b3a 0%, 15%, #101c2e 100%) !important;

    & .header {
      border-bottom: 1px solid $dark-light-shade2;
      color: $neutral-light-shade3;

      & .title {
        color: $dark-text-primary;
      }
    }

    & .text {
      color: $dark-text-primary;
    }

    & .card {
      border: 1px solid $dark-light-shade2;
      background: $dark-primary;
    }
  }

  & label {
    color: $dark-text-primary;
  }

  & .custom-input,
  & input {
    color: $dark-text-primary;
    border: 1px solid $dark-light-shade2;
    background: $dark-secondary;
  }

  & .useravatar-details {
    & .title {
      color: $dark-text-primary;
    }
  }

  & .admin-search-input-div {
    & .toolbar-eye-btn {
      & .chevron-icon {
        left: 100%;
      }
    }
  }

  & .user-checklist {
    & li {
      &.active,
      &:hover {
        color: $neutral-dark;
      }

      & .notfound {
        color: $white;
      }
    }
  }

  & .adminuser-block {
    border: 1px solid $dark-light-shade2;
  }

  & .user-checklist {
    & ul {
      border-right: 1px solid $dark-light-shade2;

      & li {
        border-bottom: 1px solid $dark-light-shade2;
      }
    }
  }

  & .profile-info {
    .protext p {
      color: $white;
    }
  }

  & .form-col {
    label {
      color: $white;
    }
  }

  .card {
    .form-row {
      .img-invert {
        filter: none;
      }
    }
  }

  & .security-card {
    .password-form {
      .forgot-password {
        color: var(--primary-indigo-400, $light-primary-shade5);
      }
    }

    .username-custom-input,
    .custom-input {
      border-color: $primary-dark-border;
    }
  }

  .profile-info {
    .protext {
      .text {
        color: $white;
      }
    }
  }

  & .btn-secondary {
    border: 1px solid $neutral-light-shade3;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: $white;
    background: transparent;

    &:hover {
      border: 1px solid $neutral-light-shade3;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: $white;
      background: $dark-secondary;
    }

    &:focus {
      border: 1px solid $neutral-light-shade3;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: $white;
      background: $dark-secondary;
    }
  }

  & .toolbar-eye-btn {
    background: transparent;
  }

  & .toogle-heading {
    .title {
      color: #fff;
    }
  }

  & .slider {
    background: var(--dark-mode-500, #384252);
  }

  & .notify-card {
    background: var(--darkmode-700, #131f2e);

    .header {
      color: $white;
    }
  }

  & .time-input-field {
    background: var(--dark-mode-800, #001023);
  }

  & p {
    color: $dark-text-primary;
  }

  & h2 {
    color: $dark-text-primary;
  }

  & .selected {
    background: var(--darkmode-700, #131f2e) !important;
    color: #fff;
  }

  & .user-checklist {
    & li {
      color: #fff !important;
    }
  }

  & .assetreduxpage {
    color: white;
  }

  & .calendar-inner {
    border-radius: 4px;
    border: 1px solid var(--dark-mode-400, #4a5463);
    background: var(--dark-mode-600, #253040);
  }

  & .calendar-sechedule-block {
    .card {
      border-radius: 8px;
      border: 1px solid var(--dark-mode-400, #4a5463);
      background: var(--dark-mode-700, #131f2e);
    }
  }

  & .dropdown-options {
    background-color: #001023;
    border: 1px solid var(--Dark-mode-400, #4a5463);
  }

  & .dropdown-options {
    & button {
      color: #fff;
      border-bottom: 1px solid var(--dark-mode-400, #4a5463);

      &:hover {
        border-radius: 6px;
        background: var(--darkmode-700, #131f2e);
      }
    }
  }

  & .dropdown-content .item:hover {
    background: var(--dark-mode-600, #253040);
  }

  $tooltip-background-color-dark: #001023;

  .tooltip-tip {
    background: $tooltip-background-color-dark;
  }

  .tooltip-tip.top::before {
    border-top-color: $tooltip-background-color-dark;
  }

  .tooltip-tip.right::before {
    border-right-color: $tooltip-background-color-dark;
  }

  .tooltip-tip.bottom::before {
    border-bottom-color: $tooltip-background-color-dark;
  }

  .tooltip-tip.left::before {
    border-left-color: $tooltip-background-color-dark;
  }

  .user-details {
    li {
      .namelabel {
        color: $white;
      }

      .info {
        color: $white;
      }
    }
  }

  /*Dashboard*/
  .main-content {
    background: var(--darkmode-800, #001023);
  }

  .img-border {
    filter: none;
  }

  .table-container-dashboard {
    .table-header-cell {
      border-top: 1px solid var(--Dark-mode-400, $dark-light-shade2);
      border-bottom: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    }
  }

  .performanceSnapshot {
    border: 1px solid var(--Dark-mode-400, #4a5463);
    background: var(--Darkmode-700, #131f2e);
  }

  .grid-item {
    border: 1px solid var(--dark-mode-300, #5d6675);
    background: var(--dark-mode-gradient-cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%));
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .card-performance {
    background: var(--dark-mode-gradient-cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%));
    border: none;
  }

  .total-count {
    text-align: center;

    & p {
      color: var(--neutral-grey-25, $dark-text-primary);
    }

    & h2 {
      color: var(--neutral-grey-25, $dark-text-primary);
    }
  }

  .text-para-dash {
    color: #fff;
  }

  .table-header-item {
    color: var(--Neutral-Grey-25, #f7f9f9);
  }

  .table-row-general {
    min-width: 180px !important;
    display: flex !important;
    border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
    padding: 16px 8px;

    & td {
      color: var(--Neutral-Grey-25, #f7f9f9);
    }
  }

  .table-header-section {
    .title {
      color: var(--Neutral-Grey-25, #f7f9f9);
    }
  }

  & .wrapper-location {
    .ui-button-toolbar {
      border-bottom: 0;
    }
  }

  .dashboard-first-block {
    .top-header {
      background-color: #131f2e;

      .page-title {
        color: #fff;
      }
    }
  }

  .dropdown-btn {
    filter: invert(1);
  }

  select {
    border: 1px solid var(--Dark-mode-300, #5d6675);
    background: var(--Dark-mode-700, #131f2e);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Neutral-Grey-25, #f7f9f9);
  }

  option {
    color: #fff;
  }

  .overflow-hidden {
    background-color: #131f2e;
  }

  .filter-img {
    filter: invert(1);
  }

  .selected-dropdown {
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-300, #5d6675);
    background: var(--Dark-mode-800, #001023);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .well-option {
    & button {
      border-bottom: none;
    }
  }

  & .setpoint-block {
    & .selected {
      border-left: 3px solid #dc4405;
      margin: 1px;
    }
  }

  & .dropdown-dashboard {
    filter: none !important;
  }

  .bg-white {
    background: #131f2e;
  }

  .tracking-wide:hover {
    color: #b3aeae;
  }

  .html,
  button,
  li {
    color: #fff;
  }

  .bg-blue-100 {
    color: #000;
  }

  .card-calendar .bg-blue-100,
  .card-calendar .bg-gray-50 {
    color: #fff;
    background: #4a5463;
  }
}

.table-row-general > td:first-child {
  color: #60bfda !important;
}

.outer-container {
  &.theme-dark {
    .group-btn {
      color: #60bfda;
    }

    .breadcrumb-separator {
      color: #fff;
    }

    .dropdown .dropdown-btn {
      filter: none;
      color: #fff;
      border: 1px solid #545d6c;
      background: var(--darkmode-800, #001023);
    }

    .fa-eye::before,
    .trend-group-button > svg,
    .card-viewer-button > svg,
    .download-button > svg,
    .asset-card-viewer-button > svg,
    .ui-settings-button > svg,
    .navigation-content-search-btn > svg,
    .card-header-icon > svg,
    .navigation-content-frame-item-carrot,
    .navigation-content-frame-item-content > svg,
    .trend-group-settings,
    .navigation-icons > svg,
    .trend-library-content-frame-item-carrot {
      filter: brightness(5);
      opacity: 0.7;
    }

    .xd-analysis-textarea {
      padding: 1rem;
      color: #f7f9f9;
    }

    .notification-wrapper .comment-box,
    .filter-dropdown,
    .filter-item {
      background-color: #1a2737;
      border-color: #444f5e;
      color: #f7f9f9;

      .node-text,
      .date-text,
      .notification-footer,
      .comment-body {
        color: #f7f9f9;
      }

      .comment-body-container {
        border-color: #444f5e;
        background-color: #253040;
      }
    }

    .card-viewer,
    .card-date-item-container,
    .card-date-item-item-container-active,
    .card-viewer-date-list,
    .rodliftanalysis-header,
    .rodliftanalysis-body,
    .gl-analysis-performance-data-header,
    .performance-data-frame,
    .analysis-result-header-container,
    .analysis-result-item-header-container,
    .analysis-result-item-container {
      background-color: #1a2737;
      border-color: #444f5e;
      color: #f7f9f9;

      .card-viewer-data {
        background-color: #1a2737;
        border-color: #444f5e;
        color: #f7f9f9;

        p {
          color: #f7f9f9;
        }
      }

      .card-header-divider {
        background-color: #444f5e;
      }

      .card-header-text {
        color: #f7f9f9;
      }
    }

    .image-view-component .data-overlay {
      box-shadow:
        0px 0px 0px 0px rgba(0, 0, 0, 0.1),
        0px 0px 1px 0px rgba(0, 0, 0, 0.1),
        1px 1px 2px 0px rgba(0, 0, 0, 0.09),
        3px 3px 3px 0px rgba(0, 0, 0, 0.05),
        6px 6px 3px 0px rgba(0, 0, 0, 0.01),
        9px 9px 4px 0px rgba(0, 0, 0, 0);
      border: 1px solid #4a5463;
      background-color: #001023;

      color: $dark-text-primary;

      .data-value.emphasis {
        background-color: #60bfda;
        border: 1px solid #60bfda;

        .label,
        .value {
          background-color: transparent;
          color: #191f23;
        }
      }

      .data-value.good {
        background-color: #001023;

        .label,
        .value {
          background-color: transparent;
          color: #32d583;
        }
      }

      .data-value.error {
        background-color: #001023;

        .label,
        .value {
          background-color: transparent;
          color: #f97066;
        }
      }

      .data-value.warning {
        background-color: #001023;

        .label,
        .value {
          background-color: transparent;
          color: #fdb022;
        }
      }

      .data-value.normal {
        background-color: #001023;

        .label,
        .value {
          background-color: transparent;
          color: #4a5463;
        }
      }
    }

    .image-view-component .data-overlay:has(.data-value.good) {
      border-color: #32d583;
    }

    .image-view-component .data-overlay:has(.data-value.error) {
      border-color: #f97066;
    }

    .image-view-component .data-overlay:has(.data-value.warning) {
      border-color: #fdb022;
    }

    .ui-button-toolbar {
      border-width: 0;
    }

    .table-row-general {
      color: #f7f9f9;
    }

    .table-header-item {
      span.sort-image {
        display: inline-block;
        padding-left: 0.25rem;

        path {
          stroke: #fff;
        }
      }
    }

    .group-status-container {
      margin: 0rem 1.5rem 1rem;
      border-radius: 8px;
    }

    .group-status-container,
    .table-header-item,
    .table-row-alarms,
    .table-row-name {
      background-color: #111e30;
      border-color: #4a5463;

      .badge {
        mix-blend-mode: exclusion;
      }
    }

    .table-container,
    .table-header-cell {
      border-color: #4a5463;
    }

    .property-list-item {
      border-color: #4a5463;

      &:last-child {
        border-color: transparent;
      }
    }

    .dropdown-label-container,
    .control-actions-content {
      border-color: #4a5463;
      background-color: #111e30;
      color: #f7f9f9;

      .custom-icon {
        filter: brightness(5);
        opacity: 0.7;
      }

      .control-actions-content,
      span {
        color: #f7f9f9;
      }

      .control-actions-content > span {
        color: #f7f9f9;
      }
    }

    .control-actions-content:hover {
      background: var(--Dark-mode-600, #253040);
    }

    div.dropdown-label-container,
    div.dropdown-switches {
      color: #f7f9f9;
      margin-top: 0rem;

      .dropdown-list {
        background-color: #111e30;

        span {
          color: #f7f9f9;
        }
      }
    }

    .trend-dropdown-container {
      background-color: #111e30;
      color: #f7f9f9;
      border-color: #4a5463;
    }

    .trend-library-dropdown-label-container {
      border-color: #4a5463;
    }

    .esp-chart-legend-label {
      color: #f7f9f9;
    }

    .performance-curve-legend-label {
      color: #f7f9f9;
    }

    .active-v2 {
      background-color: #111e30;
      color: #5dbad4 !important;
      border-color: #4a5463;
    }

    .performance-curve-frame,
    .toolbar-dropdown-container,
    .gl-liftanalysis-body,
    .active,
    .active-v2 {
      background-color: #111e30;
      color: #f7f9f9;
      border-color: #4a5463;

      .card-header-text {
        color: #f7f9f9;
      }

      .card-header-divider {
        background-color: #444f5e;
      }

      .tab-v2 > img {
        filter: brightness(5);
        opacity: 0.7;
      }

      .inactive {
        color: #000000;
      }

      .gl-valve-analysis-table-header-cell {
        background-color: #444f5e;
        color: #f7f9f9;
      }

      .pressure-profile-legend-label,
      .gl-valve-analysis-table-header-item {
        color: #f7f9f9;
      }
    }

    .timeline-container {
      border-color: #d0d8dd;

      & .timeline-date-range-box {
        background-color: #0094bd;
      }

      .timeline-tick-line {
        background-color: #d0d8dd;
      }

      .timeline-tick-label {
        color: #f7f9f9;
      }
    }

    .timeline-card-viewer-container-border {
      border-color: #f7f9f9;
    }

    .timeline-scrollbar-inner {
      background-color: #253040;
    }

    .trend-custom-views-dropdown-btn {
      background: #131f2e !important;
      border-color: #4a5463 !important;
      color: #f7f9f9 !important;
    }

    .trend-custom-views-dropdown-content {
      background: #131f2e !important;
      border-color: #4a5463 !important;
      color: #f7f9f9 !important;
    }

    .timeline-scrollbar-container {
      background-color: #001023;
    }

    .timeline-scrollbar-button {
      background-color: #384252;
    }

    .card-viewer-quick-date {
      background-color: #384252;

      span {
        color: #f7f9f9;
      }
    }

    .quick-date-selected {
      background-color: #001023;

      span {
        color: #5dbad4 !important;
      }
    }

    .ui-icon-button-toolbar,
    .ui-icon-button-toolbar-button {
      background-color: #384252;

      .ui-icon-button-toolbar-icon.semi-bold {
        filter: brightness(5);
        opacity: 0.7;
      }

      .ui-icon-button-toolbar-button-active {
        background-color: #001023;
      }
    }

    // esp pressure profile
    .pump-icon {
      background-color: #fff;
    }

    .esp-pressure-profile-casing-image {
      background-color: #1a2737;
    }

    .esp-pressure-profile-tubing-image {
      background-color: #1a2737;
    }

    .esp-pressure-profile-well-head-image {
      border-color: #fff;
    }

    .esp-pressure-profile-casing-plug-image {
      border-color: #fff;
    }

    .esp-pressure-profile-casing-image {
      border-color: #fff;
    }
  }

  .trend-library-item-details {
    padding: 0 1rem;

    .checkbox-label {
      color: #a3b0b8;
      font-size: 14px;
    }
  }

  .trend-library-content-frame-item-text {
    color: #fff;
    font-size: 14px;
  }

  .trend-library-content-frame-item:hover {
    background-color: #444f5e;
  }

  .select-group-title {
    color: var(--Neutral-Grey-25, #f7f9f9);
  }

  .select-group-text {
    color: #b8c5cc;
  }

  .checkbox-label {
    color: #a3b0b8;
  }

  .tracking-wide {
    li {
      padding: 10px 20px;
      background: #131f2e;
      margin-bottom: 4px;
      color: white !important;
      border-radius: 6px;
    }
  }
}
