.asset-nav-bar {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: white;
}

.trend-group-button-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.trend-group-button-container.ui {
  padding-right: 32px;
}

.asset-card-viewer-button {
  display: flex;
  padding: 0.25rem;
  padding-right: 0.1rem;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
}

.trend-group-button {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
}

.trend-group-button:hover {
  cursor: pointer;
}

.asset-status-refresh-icon {
  cursor: pointer;
}
