.select-group-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.select-group-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 266px;
  gap: 4px;
}

.select-group-title {
  font-size: 19px;
  line-height: 23px;
  font-weight: 600;
  text-align: center;
}

.select-group-text {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
}
