.group-status-widget-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);
  height: 253px;
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.group-status-download-container {
  padding: 10px;
  width: 128px;
  position: absolute;
  top: 29px;
  right: 0;
  border-radius: 3px;
  border: var(--Number, 1px) solid var(--Neutral-Grey50, #e7ecee);
  background: var(--Shades-White, #fff);
  box-shadow:
    0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.group-status-download-container:hover {
  cursor: pointer;
}

.group-status-dropdown {
  position: relative;
}

.group-status-widget-body {
  display: flex;
  padding: 0px 13px;
  overflow-y: auto;
}

.theme-dark {
  .group-status-download-container {
    background: #253040;
    color: #fff;
    border-color: #424d5c;

    &:hover {
      background: var(--dark-mode-800, #273446) !important;
    }
  }

  .group-widgets-no-data-text {
    color: var(--Neutral-Grey-200, var(--Neutral-Grey200, #b8c5cc));
  }
}

.group-widgets-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.group-widget-failed-to-load-text {
  text-align: center;
  line-height: 20px;
}

.group-widgets-no-data-text {
  font-size: 19px;
  color: var(--Neutral-Grey-800, var(--Neutral-Grey8));
  text-align: center;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.38px;
}