.quick-select-time-container {
  width: 107.12px;
  height: 20.06px;
  gap: 16px;
}
.quick-select-time-button {
  width: 19px;
  height: 20px;
  gap: 8px;
  //styleName: Paragraph 1 [P1]/Medium;
  font-family: Mulish;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #00789e;
  margin-left: 10px;
}

@media (min-width: 768px) and (max-width: 1366px) {
  .trend-custom-dropdown .dropdown {
    margin-left: 10px;
  }

  .trend-custom-dropdown-group-view .dropdown {
    margin-left: 8px;
  }
}

.trend-custom-dropdown .dropdown {
  margin-left: 10px;
}

.trend-custom-dropdown-group-view .dropdown {
  margin-left: 8px;
}

.trend-custom-dropdown-group-view .dropdown-btn,
.trend-custom-dropdown .dropdown-btn {
  overflow-y: hidden;
}

.full-screen .dropdown {
  width: 320px;
}

.full-screen .dropdown-content {
  width: 320px;
}
