.nodal-analysis-legend-container {
  display: flex;
  width: 100%;
}

.nodal-analysis-legend-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
}
