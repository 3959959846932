.outer-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 100vh;
}

.outer-container.theme-dark .asset-nav-bar {
  border-width: 0 0 1px 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin-bottom: 12px;
}

.switch-container {
  position: relative;
}

.switch.ui {
  position: absolute;
  right: 8px;
  top: 26px;
  z-index: 11;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.3s;
  border-radius: 15px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  transition: transform 0.3s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4caf50;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(18px);
}

/* Style the h2 label text */
h2 {
  margin-left: 50px;
  margin-top: -20px;
  font-size: 14px;
  font-weight: 700;
  color: black;
}

.theme-toggle {
  position: absolute;
  width: auto;
  padding: 0;
  z-index: 999;
  margin: 0;
  right: 30px;
  top: 20px;
}

.assetreduxpage {
  position: relative;
  padding-left: 10px;
  padding-top: 12px;
}

.navbar-container {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
}

.outlet-container {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  min-width: 1200px;
  margin-left: 77px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.logout-button {
  display: inline-block;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background-color: #7aa8b7;
  border-radius: 6px;
  outline: none;
  transition: 0.3s;
}

.logout-button:hover {
  background-color: #c2c7c7;
}

.logout-wrap {
  position: relative;
  top: 4rem;
  left: 50%;
  margin-top: -3rem;
  width: 6rem;
}

.footer-container {
  display: flex;
  height: 40px;
  color: var(--Neutral-Grey-200, #b8c5cc);
  font-size: 14px;
  width: 100%;
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  justify-content: center;
  background-color: #001023;
  justify-self: end;
}
