.custom-switch {
  position: relative;
  display: inline-flex;
  height: 1.5rem;
  width: 2.75rem;
  flex-shrink: 0;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 10px;
  outline: none;
}

.custom-switch.checked {
  background-color: #00789e;
}

.custom-switch.unchecked {
  background-color: #e7ecee;
}

.theme-dark {
  .custom-switch.unchecked {
    background-color: #384252;
  }
}

.custom-switch-handle {
  pointer-events: none;
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  transform: translateX(0);
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 0 2px transparent;
  transition: transform 200ms ease-in-out;
}

.custom-switch-handle.checked {
  transform: translateX(1.25rem);
}