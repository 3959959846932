.xd-analysis-container {
  width: 1000px;
  display: flex;
}

.full-screen .xd-analysis-container {
  width: 3500px;
}

.xd-analysis-text-container {
  width: 100%;
  height: 100%;
}

.xd-analysis-textarea {
  resize: none;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: 1.25rem;
  color: var(--neutral-grey-600, #4b5e68);
}