.checkbox-parent {
  display: flex;
  gap: 10px;
}

.checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Grey-200, #b8c5cc);
  background: var(--Shades-White, #fff);
}

.checkbox-check-mark {
  margin-left: 3px;
  width: 13px;
  height: 18px;
  color: #fff;
}

.checkbox-checked {
  border: 1px solid var(--primary-indigo-600, #00789e);
  background: var(--primary-indigo-25, #00789e);
}

.checkbox-label {
  font-size: 16px;
  font-weight: var(--font-weight-medium);
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #323f46;
}
