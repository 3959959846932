.performance-curve-legend-container {
  display: flex;
  width: 100%;
}

.performance-curve-legend-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 14px;
  row-gap: 8px;
}

.performance-curve-legend-label {
  color: #323f46;
  font-size: 14px;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  line-height: 20px;
}

.performance-curve-legend-circle {
  width: 12px;
  height: 12px;
  margin-right: 4px;
  border-radius: 100%;
}
